import React from 'react';
import {Tooltip, Button} from 'antd';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestion} from '@fortawesome/free-solid-svg-icons';

const EmailNotificationsInfo = () => {
	const {t} = useTranslation();

	return (
		<Tooltip
			title={t('contact-information.form-email-notifications-info')}
			color={'white'}
			placement={'topRight'}
			trigger={['click']}
			overlayClassName={'contactinformation__emailnotification__info__tooltip'}
		>
			<Button
				className={'contactinformation__emailnotification__info'}
				icon={<FontAwesomeIcon icon={faQuestion} />}
				size={'small'}
			/>
		</Tooltip>
	);
};

export default EmailNotificationsInfo;