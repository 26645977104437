import React from 'react';
import {
	BrowserRouter,
	Route,
	Routes,
} from 'react-router-dom';

import {
	CreateOrEditForm,
	Error,
	ViewBill,
} from './routes';
import Message from './components/Message';
import PageTitle from './components/PageTitle';

const Router = () => (
	<BrowserRouter>
		<Message />
		<PageTitle />
		<Routes>
			<Route path={'/'} element={<CreateOrEditForm />} />
			<Route path={'/edit/:billId/:editHash'} element={<CreateOrEditForm edit={true} />} />
			<Route path={'/view/:billId/:viewHash'} element={<ViewBill />} />
			<Route path={'/error/*'} element={<Error />} />
			<Route path={'*'} element={<Error error404={true} />} />
		</Routes>
	</BrowserRouter>
);

export default Router;
