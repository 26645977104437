import {UseMutationResult, useMutation} from '@tanstack/react-query';
import qs from 'query-string';

import Api from './api';
import {AxiosResponse} from 'axios';

export type DeleteFileData = {
	id: string;
	billEditHash: string | null;
};

export type DeleteFileResponse = {
	status: 'deleted' | 'error';
};

const deleteFile = async ({id, billEditHash}: DeleteFileData) => {
	const queryString = qs.stringify({billEditHash, key: id});
	const data: AxiosResponse<DeleteFileResponse> = await Api.delete(`/files/delete?${queryString}`);
	return data;
};

export const useDeleteFile = (): UseMutationResult<AxiosResponse<DeleteFileResponse>, unknown, DeleteFileData, unknown> => {
	return useMutation({
		mutationFn: deleteFile,
	});
};