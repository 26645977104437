export const getAccountSlug = (): string => {
	if (process.env.NODE_ENV === 'development') return 'testi';

	const hostList = window.location.host.split('.');
	if (hostList[0] === 'www') {
		return hostList[1];
	}

	return hostList[0];
};

