import {ContactInformationType} from '../types';

export const saveUserInformationToCookies = (contactInformation: ContactInformationType): void => {
	const contactInformationKeys = Object.keys(contactInformation);

	if (contactInformation['save-information']) { // Save cookies to browser
		// Set cookie to expire in one year
		const d = new Date();
		d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
		const expires = `expires=${d.toUTCString()}`;

		contactInformationKeys.forEach((key) => {
			if (key === 'undefined') return document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;SameSite=Strict;path=/;`;
			// @ts-ignore
			return document.cookie = `${key}=${contactInformation[key]};${expires};SameSite=Strict;path=/`;
		});
	} else { // Delete cookies
		contactInformationKeys.forEach((key) => {
			return document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;SameSite=Strict;path=/;`;
		});
	}
};