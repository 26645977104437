import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import Api from './api';
import {getAccountSlug} from '../utils/getAccountSlug';

export type AuthorizePassword = {
	password: string;
};

export type AuthorizePasswordResponse = {
	status: 'authorized';
};

const authorizePassword = async (body: AuthorizePassword) => {
	const slug = getAccountSlug();
	const data: AxiosResponse<AuthorizePasswordResponse> = await Api.post(`/account/${slug}/form-password`, {data: body});
	return data;
};

export const useAuthorizePassword = (): UseMutationResult<AxiosResponse<AuthorizePasswordResponse>, unknown, AuthorizePassword, unknown> => {
	return useMutation({
		mutationFn: authorizePassword,
	});
};