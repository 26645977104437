import React, {useMemo, useEffect} from 'react';
import {
	Card,
	Form,
	Input,
	Checkbox,
} from 'antd';
import {useTranslation, Trans} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faUser,
	faEnvelope,
	faPhone,
	faBook,
	faAddressCard,
} from '@fortawesome/free-solid-svg-icons';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isIBAN from 'validator/lib/isIBAN';

import Description from '../Description';
import EmailNotificationsInfo from './components/EmailNotificationsInfo';
import {ExistingBill} from '../../../../queries/useGetExistingBill';
import {formatIban} from '../../../../utils/formatIban';

type Props = {
	collectIdentities: boolean;
	editBill: ExistingBill | null;
};

const ssnRegExp = new RegExp(/^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([5-9]\d\+|\d\d-|[01]\dA)\d{3}[\dABCDEFHJKLMNPRSTUVWXY]$/);

const ContactInformation = ({collectIdentities, editBill}: Props) => {
	const [form] = Form.useForm();
	const {t} = useTranslation();

	// Get data from cookies or from edit data
	useEffect(() => {
		const cookiesString = decodeURIComponent(document.cookie);
		const cookies = cookiesString.split(';').map((string) => string.trim().split('='));

		cookies.forEach(([key, value]) => {
			if (key === 'iban') {
				form.setFieldValue(key, formatIban(value));
			} else {
				form.setFieldValue(key, value);
			}
		});

		if (editBill !== null) { // Set values from edit bill
			form.setFieldValue('name', editBill.billCreatorName);
			form.setFieldValue('email', editBill.billCreatorEmail);
			form.setFieldValue('phone', editBill.billCreatorPhone);
			form.setFieldValue('iban', formatIban(editBill.billCreatorIban));
			form.setFieldValue('ssn', editBill.billCreatorIdentity);
		}
	}, [form, editBill]);

	const validateMessages = useMemo(() => ({
		required: t('contact-information.form-required-item'),
		types: {
			email: t('contact-information.form-email-not-valid'),
		},
	}), [t]);

	return (
		<div className={'contactinformation'}>
			<Card className={'contactinformation__card'} title={t('contact-information.title')}>
				<Form
					name={'contact-information'}
					validateTrigger={'onBlur'}
					validateMessages={validateMessages}
					form={form}
				>
					<Form.Item name={'name'} rules={[{required: true}]}>
						<Input
							addonBefore={<FontAwesomeIcon icon={faUser} />}
							placeholder={t('contact-information.form-user-name')}
						/>
					</Form.Item>

					<Form.Item name={'email'} rules={[{required: true, type: 'email'}]}>
						<Input
							addonBefore={<FontAwesomeIcon icon={faEnvelope} />}
							placeholder={t('contact-information.form-user-email')}
						/>
					</Form.Item>

					<Form.Item
						name={'phone'}
						rules={[
							{required: true},
							{
								validator: (_, value) => {
									if (value && !isMobilePhone(value as string)) {
										return Promise.reject(t('contact-information.form-phone-not-valid'));
									} else {
										return Promise.resolve();
									}
								},
							},
						]}

					>
						<Input
							addonBefore={<FontAwesomeIcon icon={faPhone} />}
							placeholder={t('contact-information.form-user-phone')}
						/>
					</Form.Item>

					<Form.Item
						name={'iban'}
						rules={[
							{required: true},
							{
								validator: (_, value) => {
									if (value && !isIBAN(value as string)) {
										return Promise.reject(t('contact-information.form-iban-not-valid'));
									} else {
										return Promise.resolve();
									}
								},
							},
						]}

					>
						<Input
							addonBefore={<FontAwesomeIcon icon={faBook} />}
							placeholder={t('contact-information.form-user-iban')}
						/>
					</Form.Item>

					<Form.Item
						name={'ssn'}
						rules={[
							{required: collectIdentities},
							{
								validator: (_, value) => {
									if (collectIdentities && value && !ssnRegExp.test(value as string)) {
										return Promise.reject(t('contact-information.form-ssn-not-valid'));
									} else {
										return Promise.resolve();
									}
								}
							}
						]}

						className={`contactinformation__card__ssn ${collectIdentities ? 'visible' : 'hidden'}`}
					>
						<Input
							addonBefore={<FontAwesomeIcon icon={faAddressCard} />}
							placeholder={t('contact-information.form-user-ssn')}
						/>
					</Form.Item>

					<Form.Item
						name={'agreement'}
						className={'contactinformation__terms'}
						valuePropName={'checked'}
						rules={[
							{
								validator: (_, value) =>
									value ? Promise.resolve() : Promise.reject(),
							},
						]}
					>
						<Checkbox>
							<Trans
								i18nKey={'contact-information.form-accept-terms'}
								components={{addLink: <a target='_blank' href='https://kululaskut.fi/tietosuoja'>link</a>}}
							/>
						</Checkbox>
					</Form.Item>

					<div className={'contactinformation__emailnotification'}>
						<Form.Item
							name={'email-notifications'}
							className={'contactinformation__emailnotification__item'}
							valuePropName={'checked'}
						>
							<Checkbox>
								{t('contact-information.form-email-notifications')}
							</Checkbox>
						</Form.Item>
						<EmailNotificationsInfo />
					</div>

					<Form.Item
						name={'save-information'}
						valuePropName={'checked'}
					>
						<Checkbox>
							{t('contact-information.form-save-information')}
						</Checkbox>
					</Form.Item>
				</Form>
			</Card>

			<div className={'contactinformation__description'}>
				<Description value={'contact-information.description'} />
			</div>
		</div>
	);
};

export default ContactInformation;