import {useCallback, useEffect, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useAccountInfo} from '../../queries/useAccountInfo';
import {refNum} from '../../routes/CreateOrEditForm/utils/refNum';

const PageTitle = () => {
	const {t} = useTranslation();
	const {pathname} = useLocation();

	const {data} = useAccountInfo();

	const accountNameSuffix = useMemo(() => {
		if (data && data.accountName) return ` | ${data.accountName}`;
		return ' | Kululaskut.fi';
	}, [data]);

	const generateTitle = useCallback((pathname: string): string => {
		if (pathname === '/') return t('page-titles.create');
		const [action, billId] = pathname.split('/').filter(x => x);
		if (action === 'view') return t('page-titles.view', {id: refNum(parseInt(billId))});
		if (action === 'edit') return t('page-titles.edit', {id: refNum(parseInt(billId))});
		return t('page-titles.page-not-found');
	}, [t]);

	useEffect(() => {
		document.title = generateTitle(pathname) + accountNameSuffix;
	}, [generateTitle, pathname, accountNameSuffix]);

	return null;
};

export default PageTitle;