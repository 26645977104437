import {useCallback, useState, useMemo, useEffect} from 'react';

import {ItemType} from '../types';
import {ExistingExpense} from '../../../queries/useGetExistingBill';

type SumState = {
	type: ItemType;
	sum: number;
}

type TotalSumState = {
	[id: string]: SumState;
};

type UseTotalSumType = {
	sum: number;
	update: (key: string, type: ItemType, sum: number) => void;
	remove: (key: string) => void;
};

export const useTotalSum = (expenses: ExistingExpense[]): UseTotalSumType => {
	const [totalSum, setTotalSum] = useState<TotalSumState>({});

	// Initialize the state if we pass expenses (if we are editing bill)
	useEffect(() => {
		const newState: TotalSumState = {};
		expenses.forEach((expense) => {
			const type = expense.expenseMileage ? 'mileage' : expense.expenseAmount > 0 ? 'invoice' : 'income';
			const name = `item_${type}_${expense.expenseId}`;
			const sum = (type === 'income' ? -1 : 1) * expense.expenseAmount;
			newState[name] = {type, sum};
			setTotalSum({...newState});
		});
	}, [expenses, setTotalSum]);

	const update = useCallback((key: string, type: ItemType, sum: number) => {
		if (!totalSum[key]) { // Create new object
			const newState: SumState = {
				type: type,
				sum: sum || 0,
			};
			setTotalSum({[key]: newState, ...totalSum});
		} else { // Update existing
			const edit = totalSum[key];
			delete totalSum[key];
			edit.sum = sum || 0;
			setTotalSum({[key]: edit, ...totalSum});
		}
	}, [totalSum, setTotalSum]);

	const remove = useCallback((key: string) => {
		const newTotalSum = Object.assign({}, totalSum);
		delete newTotalSum[key];
		setTotalSum(newTotalSum);
	}, [totalSum, setTotalSum]);

	const total = useMemo(() => {
		const keys = Object.keys(totalSum);
		return keys.reduce((acc, cur) => {
			if (totalSum[cur].type === 'income') {
				return acc - totalSum[cur].sum;
			}
			return acc + totalSum[cur].sum;
		}, 0);
	}, [totalSum]);

	return {
		sum: total,
		update,
		remove,
	};
};