import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
	Card,
	Form as AntdForm,
	Button,
} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';

import {Item} from '../../../../types';
import {MileageTypeData, CategoryData} from '../../../../../../queries/useAccountInfo';
import {generateForm} from './generateForm';
import Inputs from '../Inputs';

type DeleteProps = {
	label: string;
	onDelete: () => void;
};

const Delete = ({label, onDelete}: DeleteProps) => (
	<Button
		type={'link'}
		className={'items__content__card__delete'}
		onClick={onDelete}
	>
		<FontAwesomeIcon icon={faXmark} color={'#dc3545'} />
		{label}
	</Button>
);

type Props = Item & {
	deleteItem: (key: string) => void;
	mileageTypes: MileageTypeData[];
	categories: CategoryData;
};

const Form = ({id, type, existingData, deleteItem, mileageTypes, categories}: Props) => {
	const {t} = useTranslation();
	const [deleting, setDelete] = useState<boolean>(false);

	const [form] = AntdForm.useForm();

	const title = useMemo(() => t(`items.${type}.title`), [t, type]);

	const formStructure = useMemo(() => generateForm(id, type, {mileageTypes, categories}), [mileageTypes, categories, id, type]);

	const onDelete = useCallback(() => {
		setDelete(true);
		setTimeout(() => {
			if (!formStructure) return;
			return deleteItem(formStructure.id);
		}, 900);
	}, [setDelete, deleteItem, formStructure]);

	const validateMessages = useMemo(() => ({
		required: t('items.form-required-item'),
		types: {
			email: t('contact-information.form-email-not-valid'),
		},
	}), [t]);

	useEffect(() => {
		// Update existing data (= when editing bill) to the form
		// Note: attachments manually updated in the component (by passing existingData to the <File /> component)
		if (existingData) {
			// Attachments are many
			if (type === 'invoice') {
				form.setFieldValue('description', existingData.expenseDescription);
				form.setFieldValue('category', existingData.expenseCategory);
				form.setFieldValue('sum', existingData.expenseAmount.toFixed(2));
			} else if (type === 'income') {
				form.setFieldValue('description', existingData.expenseDescription);
				form.setFieldValue('category', existingData.expenseCategory);
				form.setFieldValue('sum', (-1 * existingData.expenseAmount).toFixed(2));
			} else if (type === 'mileage') {
				form.setFieldValue('description', existingData.expenseDescription);
				form.setFieldValue('category', existingData.expenseCategory);
				form.setFieldValue('mileageCategory', Number(existingData.expenseMileageType));
				form.setFieldValue('distance', existingData.expenseMileage?.toFixed(2));
				form.setFieldValue('totalDistance', existingData.expenseAmount.toFixed(2));
			}
		}
	}, [existingData, form, type]);

	return (
		<Card
			className={`items__content__card ${deleting ? 'hide' : 'show'}`}
			title={title}
			extra={<Delete label={t('items.delete')} onDelete={onDelete} />}
		>
			{formStructure &&
				<AntdForm
					name={`${formStructure.id}`}
					validateTrigger={'onBlur'}
					validateMessages={validateMessages}
					form={form}
				>
					{formStructure?.fields.map((field) => (
						<Inputs {...field} formInstance={form} key={`form_${formStructure.id}_${field.id}`} existingData={existingData} />
					))}
				</AntdForm>
			}
		</Card>
	);
};

export default Form;