export enum BillLanguage {
	fi = 'fi',
	en = 'en',
	sv = 'sv',
}

export type Attachment = {
	id: string;
	url: string;
};

export type ExpenseResult = {
	expenseAmount: number;
	expenseCategory: string;
	expenseDescription: string;
	expenseId: number;
	expenseMileage: number | null;
	expenseMileageType: string | null;
	files: Attachment[];
};


export type BillResult = {
	billId: number;
	billEditHash: string;
	billRefnum?: number;
	billCreatorEmail: string;
	billCreatorIban: string;
	billCreatorName: string;
	billCreatorPhone: string;
	billStatus: string;
	billTimestamp: string;
	billPaidTimestamp?: string;
	accountName?: string;
	accountIban?: string;
	billSum?: number;
	expenses: ExpenseResult[];
	billLanguage?: BillLanguage;
};