import React, {useCallback, useState, useMemo, useEffect} from 'react';
import {
	Form,
	Modal,
	Spin,
	Upload,
	Button,
	message,
	FormInstance,
} from 'antd';
import type {RcFile, UploadChangeParam, UploadFile} from 'antd/es/upload/interface';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faUpload,
	faRotateLeft,
	faRotateRight,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import {AxiosResponse} from 'axios';
import {useParams} from 'react-router-dom';

import {FileUploadResponse} from '../../../../../types';
import {useRotateImage, RotateImageResponse} from '../../../../../../../queries/useRotateImage';
import {useDeleteFile} from '../../../../../../../queries/useDeleteFile';
import {ExistingFile} from '../../../../../../../queries/useGetExistingBill';

const {Dragger} = Upload;

type FileProps = {
	id: string;
	required: boolean;
	label?: string;
	existingFiles: ExistingFile[];
	formInstance: FormInstance;
};

const File = ({id, required, label = '', existingFiles, formInstance}: FileProps) => {
	const {t} = useTranslation();
	const {editHash} = useParams();
	const [files, setFiles] = useState<UploadFile<FileUploadResponse>[]>([]);
	const [isModalOpen, setModalOpen] = useState<FileUploadResponse | false>(false);
	const {isLoading: isRotateImageLoading, ...rotateImage} = useRotateImage();
	const {isLoading: isDeleteFileLoading, ...deleteFile} = useDeleteFile();
	const [messageApi, messageContextHolder] = message.useMessage();


	useEffect(() => {
		if (existingFiles.length > 0) {
			const files: UploadFile<FileUploadResponse>[] = existingFiles.map((file) => ({
				uid: file.name,
				name: file.name,
				status: 'success',
				url: file.url,
				percent: 100,
				response: {
					status: 'success',
					url: file.url,
					id: file.name,
				},
			}));
			formInstance.setFieldValue('files', {file: files[0], fileList: files});
			setFiles(files);
		}
	}, [existingFiles, formInstance]);

	const showError = useCallback(() => {
		messageApi.open({
			type: 'error',
			content: t('errors.something-went-wrong'),
			duration: 5,
		});
	}, [messageApi, t]);

	const onFileChange = useCallback((e: UploadChangeParam<UploadFile<FileUploadResponse>>) => {
		const {file, fileList} = e;
		if (!file.status) return;
		setFiles([...fileList]);
	}, [setFiles]);

	const onRotate = useCallback((direction: 'left' | 'right') => {
		if (!isModalOpen) return;
		const data = {
			id: isModalOpen.id || '',
			direction,
		};

		rotateImage.mutate(data, {
			onSuccess: (result: AxiosResponse<RotateImageResponse>) => {
				if (result.status === 200) {
					setModalOpen(result.data);
					const newFiles = [...files].map((x) => {
						if (x.response?.id === result.data.id) {
							return {
								...x,
								url: result.data.url,
								response: result.data,
							};
						}
						return x;
					});
					setFiles(newFiles);
				}
			},
			onError: () => {
				showError();
			},
		});

	}, [isModalOpen, rotateImage, files, setFiles, showError]);

	const onCloseModal = useCallback(() => {
		setModalOpen(false);
	}, [setModalOpen]);

	const onDelete = useCallback((id?: string) => {
		if (id) {
			const existing = existingFiles.find((existingFile) => existingFile.name === id);
			deleteFile.mutate({id, billEditHash: existing && editHash ? editHash : null}, {
				onSuccess: () => {
					onCloseModal();
					const newFiles = [...files].filter((x) => x.response?.id !== id);
					setFiles(newFiles);
					messageApi.open({
						type: 'success',
						content: t('items.files.file-deleted'),
						duration: 5,
					});
				},
				onError: () => {
					showError();
				}
			});
		}
	}, [deleteFile, files, setFiles, onCloseModal, messageApi, showError, t, editHash, existingFiles]);

	const onPreviewFile = useCallback((data?: FileUploadResponse) => {
		if (!data || !data.id || !data.url) return showError();

		const mimetype = data.id?.split('.').at(-1);
		// Open PDFs in new tab
		if (mimetype === 'pdf') return window.open(data.url);

		// Open images in modal
		return setModalOpen(data);
	}, [showError]);

	const beforeUpload = useCallback((file: RcFile) => {
		const isLt10Mb = file.size / 1000 / 1000 < 10;
		if (!isLt10Mb) {
			message.open({
				type: 'error',
				content: t('items.files.too-large-file'),
				duration: 5,
			});
		}
		return isLt10Mb;
	}, [t]);

	const isLoading = useMemo(() => isRotateImageLoading || isDeleteFileLoading, [isRotateImageLoading, isDeleteFileLoading]);

	return (
		<>
			{messageContextHolder}
			<Modal
				open={isModalOpen !== false}
				onCancel={onCloseModal}
				footer={null}
			>
				{isModalOpen !== false && (
					<div className={'form__item__image__modal'}>
						{isLoading && (
							<div className={'form__item__image__modal__loadingindicator'}>
								<Spin size={'large'} />
							</div>
						)}
						<img src={isModalOpen.url} />
						<Button onClick={() => onRotate('left')} disabled={isLoading}>
							<FontAwesomeIcon icon={faRotateLeft} className={'form__item__image__modal__icon__left'} />
							{t('items.files.rotate-anticlockwise')}
						</Button>
						<Button onClick={() => onDelete(isModalOpen.id)} disabled={isLoading} danger={true}>
							<FontAwesomeIcon icon={faTrash} className={'form__item__image__modal__icon__left'} />
							{t('items.files.remove')}
						</Button>
						<Button onClick={() => onRotate('right')} disabled={isLoading}>
							{t('items.files.rotate-clockwise')}
							<FontAwesomeIcon icon={faRotateRight} className={'form__item__image__modal__icon__right'} />
						</Button>
					</div>
				)}
			</Modal>
			<Form.Item
				name={id}
				rules={[{required}]}
			>
				<Dragger
					fileList={files}
					onChange={onFileChange}
					listType={'picture'}
					action={'/api/files/upload'} name={'file'}
					accept={'.jpg, .jpeg, .png, .pdf'}
					onPreview={(file: UploadFile<FileUploadResponse>) => onPreviewFile(file.response)}
					onRemove={(file: UploadFile<FileUploadResponse>) => onDelete(file.response?.id)}
					className={'form__item__file__upload'}
					multiple={true}
					beforeUpload={beforeUpload}
				>
					<FontAwesomeIcon icon={faUpload} size={'xl'} className={'form__item__file__upload__icon'} />
					<p className={'ant-upload-text'}>{label}</p>
					<p className={'ant-upload-hint'}>
						{t('items.files.add-attachment-info')}
					</p>
				</Dragger>
			</Form.Item>
		</>
	);
};

export default File;