import {useQuery} from '@tanstack/react-query';

import Api from './api';
import {AxiosError, AxiosResponse} from 'axios';
import {BillLanguage} from './types';
import i18n from '../i18n';

type GetExistingBillRequest = {
	billId?: number;
	billEditHash?: string;
};

export type ExistingFile = {
	format: string;
	url: string;
	name: string;
};

export type ExistingExpense = {
	expenseId: number;
	expenseNum: number;
	billId: number;
	expenseAmount: number;
	expenseDescription: string;
	expenseCategory: string;
	expenseMileage: number | null;
	expenseMileageType: string | null;
	files: ExistingFile[];
};

export type ExistingBill = {
	billId: number;
	billRefnum: number;
	billTimestamp: string;
	billCreatorName: string;
	billCreatorEmail: string;
	billCreatorPhone: string;
	billCreatorIban: string;
	billCreatorIdentity: string;
	billEditComments: string;
	expenses: ExistingExpense[];
	billLanguage: BillLanguage;
};

type GetExistingBill = {
	data: ExistingBill;
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
	error: unknown;
};

const emptyData = {
	billId: 0,
	billRefnum: 0,
	billTimestamp: '',
	billCreatorName: '',
	billCreatorEmail: '',
	billCreatorPhone: '',
	billCreatorIban: '',
	billCreatorIdentity: '',
	billEditComments: '',
	expenses: [],
	billLanguage: BillLanguage.fi,
};

const getExistingBill = async ({billId, billEditHash}: GetExistingBillRequest) => {
	const {data}: AxiosResponse<ExistingBill> = await Api.get(`/bill/edit/${billId}/${billEditHash}`);

	// Change site language if bill language is different
	if (data.billLanguage && !i18n.language.includes(data.billLanguage)) {
		i18n.changeLanguage(data.billLanguage);
	}

	return data;
};

export const useGetExistingBill = ({billId, billEditHash}: GetExistingBillRequest): GetExistingBill => {
	const query = useQuery({
		queryKey: ['existingBill', billId, billEditHash],
		queryFn: () => getExistingBill({billId, billEditHash}),
		enabled: !!billId && !!billEditHash,
		retry: (count, error: AxiosError) => {
			if (error.response?.status === 404) return false;
			return true;
		},
		refetchInterval: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});

	return {
		data: query.data || emptyData,
		isLoading: query.isLoading,
		isSuccess: query.isSuccess,
		isError: query.isError,
		error: query.error,
	};
};