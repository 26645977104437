import React from 'react';
import {Typography} from 'antd';
import {Trans} from 'react-i18next';

const {Paragraph} = Typography;

type Props = {
	value: string;
};

const Description = ({value}: Props) => {
	return (
		<Paragraph className={'description'}>
			<Trans>{value}</Trans>
		</Paragraph>
	);
};

export default Description;