import React, {useCallback} from 'react';
import {Modal, Button, Typography} from 'antd';
import {Trans, useTranslation} from 'react-i18next';

const {Paragraph, Title} = Typography;

const AccountNotActive = () => {
	const {t} = useTranslation();

	const onClose = useCallback(() => {
		window.close();
	}, []);

	return (
		<Modal
			open={true}
			footer={null}
			closeIcon={null}
		>
			<div className={'passwordmodal'}>
				<Title level={3}>{t('account-not-active.title')}</Title>
				<Paragraph>
					<Trans
						i18nKey={'account-not-active.info'}
						components={{mailto: <a target='_blank' href='mailto:info@kululaskut.fi'></a>}}
					/>
				</Paragraph>
				<Button type={'primary'} onClick={onClose}>
					{t('account-not-active.close')}
				</Button>
			</div>
		</Modal>
	);
};

export default AccountNotActive;