import React, {useState, useCallback, useMemo, useEffect} from 'react';
import {Alert, Button, Card, Typography, Modal} from 'antd';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';

import {BillResult, ExpenseResult} from '../../queries/types';
import {ItemType} from '../../routes/CreateOrEditForm/types';
import {MileageTypeData, useAccountInfo} from '../../queries/useAccountInfo';
import {formatIban} from '../../utils/formatIban';
import {formatNumber} from '../../utils/formatNumber';

const {Text, Title, Paragraph, Link} = Typography;

type ContactInformationRowProps = {
	label: string;
	value: string | React.ReactNode;
};

const ContactInformationRow = ({label, value}: ContactInformationRowProps) => (
	<div className={'success__card__contact__row'}>
		<Text strong>{label}</Text>
		<Text>{value}</Text>
	</div>
);

type ImagePreviewModalProps = {
	url: string | null;
	closeModal: () => void;
};

const ImagePreviewModal = ({url, closeModal}: ImagePreviewModalProps) => {
	return (
		<Modal
			open={url !== null}
			onCancel={closeModal}
			className={'success__image__modal'}
			footer={null}
		>
			<img src={url || ''} />
		</Modal>
	);
};

type ExpenseProps = ExpenseResult & {
	mileageTypes: MileageTypeData[];
}

const Expense = ({
	expenseId,
	expenseAmount,
	expenseDescription,
	expenseCategory,
	expenseMileage,
	expenseMileageType,
	mileageTypes,
	files,
}: ExpenseProps) => {
	const {t} = useTranslation();
	const [imagePreview, setImagePreview] = useState<string | null>(null);

	const type: ItemType = useMemo(() => {
		if (expenseMileage && expenseMileageType) return 'mileage';
		if (expenseAmount > 0) return 'invoice';
		return 'income';
	}, [expenseMileage, expenseMileageType, expenseAmount]);

	const amount: string = useMemo(() => formatNumber(expenseAmount, '€'), [expenseAmount]);
	const {mileageCategory, pricePerKm} = useMemo(() => {
		if (type === 'mileage') {
			const mileage = mileageTypes.find((x) => x.mileageId === Number(expenseMileageType));
			return {
				mileageCategory: mileage?.mileageType || '',
				pricePerKm: formatNumber(mileage?.mileageAmount, '€/km') || '',
			};
		}
		return {mileageCategory: '', pricePerKm: ''};
	}, [type, mileageTypes, expenseMileageType]);

	const distance = useMemo(() => {
		return formatNumber(expenseMileage, 'km');
	}, [expenseMileage]);

	const openAttachment = useCallback((id: string, url: string) => {
		const mimetype = id.split('.').at(-1);
		if (mimetype === 'pdf') return window.open(url);
		return setImagePreview(url);
	}, []);

	return (
		<div className={'success__card__invoice'}>
			<ImagePreviewModal url={imagePreview} closeModal={() => setImagePreview(null)} />
			<Title level={4}>{t(`success.types.${type}`)}</Title>
			<Paragraph>{expenseDescription}</Paragraph>
			{expenseCategory && <Paragraph><Text strong>{t('success.category')}:</Text> {expenseCategory}</Paragraph>}
			{(mileageCategory && pricePerKm) && <Paragraph>{mileageCategory} ({pricePerKm})</Paragraph>}
			{distance && <Paragraph>{distance}</Paragraph>}
			<Paragraph strong>{amount}</Paragraph>
			{files.length > 0 &&
				<>
					<Text strong>{t('success.attachments')}</Text>
					<Paragraph>
						<ul>
							{files.map(({id, url}, index) => (
								<li key={`invoice_${expenseId}_attachment_${index}`}>
									<Link onClick={() => openAttachment(id, url)}>{t('success.attachment-number', {index: index + 1})}</Link>
								</li>
							))}
						</ul>
					</Paragraph>
				</>
			}
		</div>
	);
};

const SubmitNewForm = () => {
	const {t} = useTranslation();

	const refresh = useCallback(() => location.replace('/'), []);

	return (
		<Button type={'primary'} className={'success__info__button'} onClick={refresh}>{t('success.new-form')}</Button>
	);
};

type Props = {
	data?: BillResult;
	showCreateNewButton: boolean;
};

const Success = ({data, showCreateNewButton}: Props) => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const {data: accountInfo} = useAccountInfo();
	const {mileageTypes} = accountInfo;

	const onEdit = useCallback(() => {
		if (!data) return;
		return navigate(`/edit/${data.billId}/${data.billEditHash}`);
	}, [data, navigate]);

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo({top: 0, behavior: 'instant'});
		}, 1);
	}, []);

	const billStatus = useMemo(() => {
		if (!data) return null;
		if (data.billPaidTimestamp) {
			return t('success.status-values.paid', {date: moment(data.billPaidTimestamp).format('D.M.YYYY')});
		}
		if (data.billStatus === 'edit') {
			return (
				<>
					{t('success.status-values.edit')}
					<Button size={'small'} type={'primary'} onClick={onEdit}>
						{t('success.edit-button')}
					</Button>
				</>
			);
		}
		if (data.billStatus) {
			return t(`success.status-values.${data.billStatus}`);
		}
		return '';
	}, [data, t, onEdit]);

	if (!data) return null;

	return (
		<div className={'success'}>
			{showCreateNewButton &&
				<Alert
					message={t('success.saved-info')}
					description={<SubmitNewForm />}
					type={'success'}
					className={'success__info'}
					showIcon={true}
				/>
			}
			<Card className={'success__card'} title={t('success.summary')}>
				<div className={'success__card__content'}>
					<div className={'success__card__contact'}>
						<ContactInformationRow label={t('success.name')} value={data.billCreatorName} />
						<ContactInformationRow label={t('success.phone')} value={data.billCreatorPhone} />
						<ContactInformationRow label={t('success.email')} value={data.billCreatorEmail} />
						<ContactInformationRow label={t('success.iban')} value={formatIban(data.billCreatorIban)} />
						<ContactInformationRow label={t('success.date')} value={moment(data.billTimestamp).format('D.M.YYYY')} />
						<ContactInformationRow label={t('success.status')} value={billStatus} />
					</div>

					{data.expenses.map((expense) => (
						<Expense {...expense} mileageTypes={mileageTypes} key={`expense_${expense.expenseId}`} />
					))}
				</div>
			</Card>
		</div>
	);
};

export default Success;