import React from 'react';
import {Button} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';

import {ItemType} from '../../../../types';

type Props = {
	addItem: (type: ItemType) => void;
	mileagesEnabled: boolean;
}

const Buttons = ({addItem, mileagesEnabled}: Props) => {
	const {t} = useTranslation();

	return (
		<div className={'items__content__buttons'}>
			<Button
				type={'primary'}
				icon={<FontAwesomeIcon icon={faPlus} />}
				className={'invoice'}
				onClick={() => addItem('invoice')}
			>
				{t('items.new-invoice')}
			</Button>

			<Button
				type={'primary'}
				icon={<FontAwesomeIcon icon={faPlus} />}
				className={'income'}
				onClick={() => addItem('income')}
			>
				{t('items.new-income')}
			</Button>

			{mileagesEnabled &&
				<Button
					type={'primary'}
					icon={<FontAwesomeIcon icon={faPlus} />}
					className={'mileage'}
					onClick={() => addItem('mileage')}
				>
					{t('items.new-mileage')}
				</Button>
			}
		</div>
	);
};

export default Buttons;