import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {DownOutlined} from '@ant-design/icons';
import {Dropdown, Button, Space} from 'antd';
import type {MenuProps} from 'antd';

const LanguageSelect = () => {
	const {t, i18n} = useTranslation();

	const onChangeLanguage = useCallback((lngKey: string) => {
		i18n.changeLanguage(lngKey);
	}, [i18n]);

	const items: MenuProps['items'] = useMemo(() => {
		return [
			{
				key: 'fi',
				label: 'Suomeksi',
				onClick: () => onChangeLanguage('fi'),
			},
			{
				key: 'en',
				label: 'In English',
				onClick: () => onChangeLanguage('en'),
			},
			{
				key: 'sv',
				label: 'På svenska',
				onClick: () => onChangeLanguage('sv'),
			}
		];
	}, [onChangeLanguage]);

	return (
		<div className={'topbar__languageselect'}>
			<Dropdown menu={{items, selectedKeys: [i18n.language]}} placement={'bottomLeft'} arrow>
				<Button ghost>
					<Space>
						{t('language-select.title')} <DownOutlined />
					</Space>
				</Button>
			</Dropdown>
		</div>
	);
};

export default LanguageSelect;