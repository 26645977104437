import {useQuery} from '@tanstack/react-query';

import Api from './api';
import {AxiosError, AxiosResponse} from 'axios';
import {BillResult} from './types';
import i18n from '../i18n';

type GetViewBill = {
	billId: number;
	billViewHash: string;
};

type GetViewBillResult = {
	data: BillResult;
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
	error: unknown;
};

const emptyData = {
	billId: 0,
	billEditHash: '',
	billCreatorName: '',
	billCreatorPhone: '',
	billCreatorEmail: '',
	billCreatorIban: '',
	billTimestamp: '',
	billStatus: '',
	expenses: [],
};

const getViewBill = async ({billId, billViewHash}: GetViewBill) => {
	const {data}: AxiosResponse<BillResult> = await Api.get(`/bill/view/${billId}/${billViewHash}`);

	// Change site language if bill language is different
	if (data.billLanguage && !i18n.language.includes(data.billLanguage)) {
		i18n.changeLanguage(data.billLanguage);
	}

	return data;
};

export const useGetViewBill = ({billId, billViewHash}: GetViewBill): GetViewBillResult => {
	const query = useQuery({
		queryKey: ['viewBill', billId, billViewHash],
		queryFn: () => getViewBill({billId, billViewHash}),
		enabled: !!billId && !!billViewHash,
		retry: (count, error: AxiosError) => {
			const status = error.response?.status;
			if (status === 401 || status === 404) return false;
			return true;
		},
		refetchInterval: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});

	return {
		data: query.data || emptyData,
		isLoading: query.isLoading,
		isSuccess: query.isSuccess,
		isError: query.isError,
		error: query.error,
	};
};