import {useCallback} from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {ArgsProps} from 'antd/es/message';

type UseMessage = {
	options: ArgsProps | null;
	open: (options: ArgsProps) => void;
}

export const useMessage = (): UseMessage => {
	const queryClient = useQueryClient();

	const query = useQuery({
		queryKey: ['use-message'],
		queryFn: () => null,
		initialData: null,
		cacheTime: 0,
	});

	const open = useCallback((options: ArgsProps) => {
		queryClient.setQueryData(['use-message'], {key: Date.now(), ...options});
	}, [queryClient]);

	return {
		options: query.data,
		open,
	};
};