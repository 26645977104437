import {UseMutationResult, useMutation} from '@tanstack/react-query';

import Api from './api';
import {AxiosResponse} from 'axios';
import {FormData, ExpenseType} from '../routes/CreateOrEditForm/types';
import {Attachment, BillResult} from './types';
import {getAccountSlug} from '../utils/getAccountSlug';
import i18n from '../i18n';

type Expense = {
	expenseNum: number;
	expenseAmount: number;
	expenseDescription: string;
	expenseCategory: string;
	expenseMileage: number | null;
	expenseMileageType: string | null;
	files: Attachment[] | null;
};

type FormDataPost = {
	accountSlug: string;
	billCreatorName: string;
	billCreatorEmail: string;
	billCreatorPhone: string;
	billCreatorIban: string;
	billCreatorIdentity: string;
	termsAccepted: boolean;
	emailNotifications: boolean;
	expenses: Expense[];
	removedExpenses: number[];
	formPassword: string;
	billLanguage: 'fi' | 'en' | 'sv';
};

const getExpenseId = (id?: string): number | null => {
	if (!id) return null;
	const split = id.split('_');
	if (!isNaN(Number(split[2]))) return Number(split[2]);
	return null;
};

const languageString = (lng: string): 'fi' | 'en' |'sv' => {
	if (lng.includes('en')) return 'en';
	if (lng.includes('sv')) return 'sv';
	return 'fi';
};

const formatFormData = (data: FormData): FormDataPost => {
	const expenses: Expense[] = data.expenses.map((expense: ExpenseType, index) => {
		const mileageParams = expense.type === 'mileage' ? {
			expenseAmount: Number(expense.totalDistance),
			expenseMileage: Number(expense.distance),
			expenseMileageType: expense.mileageCategory.toString(),
			files: [],
		} : {
			expenseAmount: expense.type === 'invoice' ? Number(expense.sum) : -1 * Number(expense.sum),
			expenseMileage: null,
			files: expense.files?.fileList.map((x) => ({id: x.response.id || '', url: x.response.url || ''})) || [],
			expenseMileageType: null,
		};

		return {
			expenseId: getExpenseId(expense.id),
			expenseNum: index + 1,
			expenseDescription: expense.description,
			expenseCategory: expense.category || '',
			...mileageParams,
		};
	});

	const slug = getAccountSlug();

	const hasMileageExpenses = !!expenses.find((expense) => !!expense.expenseMileageType);

	const removedExpenses = (data.removedItems || []).map((item) => {
		const split = item.split('_');
		return Number(split[2]) || 0;
	});

	return {
		accountSlug: slug,
		billCreatorName: data.contactInformation.name,
		billCreatorEmail: data.contactInformation.email,
		billCreatorPhone: data.contactInformation.phone,
		billCreatorIban: data.contactInformation.iban.replace(/ /g, ''),
		billCreatorIdentity: hasMileageExpenses ? data.contactInformation.ssn : '',
		termsAccepted: data.contactInformation.agreement === true || data.contactInformation.agreement === 'true',
		emailNotifications: data.contactInformation['email-notifications'] === true || data.contactInformation['email-notifications'] === 'true',
		expenses,
		removedExpenses,
		formPassword: data.formPassword,
		billLanguage: languageString(i18n.language),
	};
};

const postForm = async (body: FormData) => {
	const formattedBody: FormDataPost = formatFormData(body);
	const data: AxiosResponse<BillResult> = await Api.post('/bills', {data: formattedBody});
	return data;
};

const updateForm = async (body: FormData) => {
	const formattedBody: FormDataPost = formatFormData(body);
	const data: AxiosResponse<BillResult> = await Api.put(`/bill/edit/${body.id || 0}/${body.billEditHash || ''}`, {data: formattedBody});
	return data;
};

export const useFormPost = (edit?: boolean): UseMutationResult<AxiosResponse<BillResult>, unknown, FormData, unknown> => {
	return useMutation({
		mutationFn: (body: FormData) => edit ? updateForm(body) : postForm(body),
	});
};