import React, {useMemo} from 'react';
import {Typography} from 'antd';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {AxiosError} from 'axios';

import Error from '../Error';
import PaymentInformation from './Components/PaymentInformation';
import TopBar from '../../components/TopBar';
import Success from '../../components/Success';
import {useAccountInfo} from '../../queries/useAccountInfo';
import {useGetViewBill} from '../../queries/useGetViewBill';
import {useWindowDimensions, windowBreakpoints} from '../../utils/screenSizes';
import {useMessage} from '../../utils/useMessage';

const {Title} = Typography;

const ViewBill = () => {
	const {width: screenWidth} = useWindowDimensions();
	const {t} = useTranslation();
	const {billId = 0, viewHash = ''} = useParams();
	const message = useMessage();

	const {data: accountInfo, isLoading: isLoadingAccountInfo, isError: isErrorAccountInfo, error: errorAccountInfo} = useAccountInfo();
	const {data: bill, isLoading: isLoadingBill, isError: isErrorBill, error: errorBill} = useGetViewBill({billId: Number(billId), billViewHash: viewHash});

	const smallScreen: boolean = useMemo(() => {
		return screenWidth <= windowBreakpoints.small;
	}, [screenWidth]);

	const titleLevel: 1 | 3 = useMemo(() => {
		if (smallScreen) return 3;
		return 1;
	}, [smallScreen]);

	const isLoading = useMemo(() => {
		return isLoadingAccountInfo || isLoadingBill;
	}, [isLoadingAccountInfo, isLoadingBill]);

	const isError = useMemo(() => {
		return isErrorAccountInfo || isErrorBill;
	}, [isErrorAccountInfo, isErrorBill]);

	if (isError) {
		if (isErrorBill && errorBill instanceof AxiosError) {
			const status = errorBill.response?.status;
			if (status === 401 || status === 404) {
				message.open({
					key: 'invalid-view-url',
					type: 'error',
					content: t('errors.view-link-invalid'),
					duration: 5,
				});
				const status = errorBill.response?.status;
				return <Error error404={status === 404} />;
			}
		}
		if (isErrorAccountInfo && errorAccountInfo instanceof AxiosError) {
			const status = errorAccountInfo.response?.status;
			return <Error error404={status === 404} />;
		}
		return <Error />;
	}

	return (
		<div className={`viewbill ${isLoading ? 'hidden' : 'visible'}`}>
			<TopBar title={accountInfo.accountName} smallScreen={smallScreen} />

			<div className={'viewbill__content'}>
				<Title level={titleLevel} className={'viewbill__title'}>{t('view.title', {num: bill.billRefnum || billId})}</Title>

				{bill.billStatus === 'archive' && !bill.billPaidTimestamp && bill.billSum && bill.billSum < 0 && (
					<PaymentInformation data={bill} />
				)}

				<Success data={bill} showCreateNewButton={false} />
			</div>
		</div>
	);
};

export default ViewBill;