import React from 'react';
import {
	Input,
	Form,
} from 'antd';

const {TextArea: AntdTextArea} = Input;

type TextAreaProps = {
	id: string;
	required: boolean;
	placeholder?: string;
};

const TextArea = ({id, required, placeholder = ''}: TextAreaProps) => {
	return (
		<Form.Item name={id} rules={[{required}]}>
			<AntdTextArea
				rows={2}
				placeholder={placeholder}
				maxLength={1000}
				showCount={true}
			/>
		</Form.Item>
	);
};

export default TextArea;