import React, {useMemo} from 'react';
import {Result} from 'antd';
import {useTranslation} from 'react-i18next';

type Props = {
	error404?: boolean;
	status?: number;
};

const Error = ({error404}: Props) => {
	const {t} = useTranslation();

	const status = useMemo(() => {
		if (error404) return '404';
		const path = window.location.pathname.split('/').filter((x) => x).at(-1);
		if (path === '404') return path;
		return '500';
	}, [error404]);

	const message = useMemo(() => {
		if (status === '404') return t('errors.page-not-found');
		return t('errors.something-went-wrong');
	}, [status, t]);

	return (
		<Result
			status={status}
			title={status}
			subTitle={message}
		/>
	);
};

export default Error;