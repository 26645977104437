import React, {useEffect, useMemo} from 'react';
import {
	Form,
	Typography,
	FormInstance,
} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/free-solid-svg-icons';

import {useAccountInfo} from '../../../../../../../queries/useAccountInfo';
import {formatNumber} from '../../../../../../../utils/formatNumber';

const {Text, Paragraph} = Typography;

type TotalDistanceProps = {
	id: string;
	required: boolean;
	label?: string;
	formInstance: FormInstance;
};

const TotalDistance = ({id, required, label = '', formInstance}: TotalDistanceProps) => {
	const distance = Form.useWatch('distance', formInstance);
	const category = Form.useWatch('mileageCategory', formInstance);

	const {data: accountInfo} = useAccountInfo();
	const {mileageTypes} = accountInfo;

	const pricePerKm = useMemo(() => {
		return mileageTypes.find((obj) => Number(obj.mileageId) === Number(category))?.mileageAmount || 0;
	}, [mileageTypes, category]);

	const [total, formattedTotal] = useMemo(() => {
		if (isNaN(distance) || isNaN(pricePerKm)) {
			return [0, '0,00 €'];
		}
		const result = distance * pricePerKm;
		return [result.toFixed(2), formatNumber(result, '€')];
	}, [distance, pricePerKm]);

	useEffect(() => {
		formInstance.setFieldValue('totalDistance', total);
	}, [total, formInstance]);

	const formattedDistance = useMemo(() => isNaN(distance) ? '0' : formatNumber(distance, 'km'), [distance]);

	const formattedCategory = useMemo(() => isNaN(pricePerKm) ? '0,00' : formatNumber(pricePerKm, '€/km'), [pricePerKm]);

	return (
		<Form.Item
			name={id}
			label={label}
			colon={false}
			className={'form__item'}
			initialValue={0}
			rules={[{required}]}
			required={false}
		>
			<Paragraph className={'form__item__totaldistance'}>
				{formattedDistance} <FontAwesomeIcon icon={faXmark} /> {formattedCategory} = <Text strong>{formattedTotal}</Text>
			</Paragraph>
		</Form.Item>
	);
};

export default TotalDistance;