import React, {useMemo} from 'react';
import {Card, Typography} from 'antd';
import {useTranslation} from 'react-i18next';

import {BillResult} from '../../../queries/types';
import {formatIban} from '../../../utils/formatIban';
import {formatNumber} from '../../../utils/formatNumber';
import {getVirtualBarcode} from '../../../utils/getVirtualBarcode';

const {Text} = Typography;

type Props = {
	data: BillResult;
};

const PaymentInformation = ({data}: Props) => {
	const {t} = useTranslation();

	const barcode = useMemo(() => {
		if (!data.accountIban || !data.billRefnum) return false;
		const barcodeData = {
			iban: data.accountIban,
			sum: Math.abs(data.billSum || 0),
			reference: data.billRefnum.toString(),
		};
		return getVirtualBarcode(barcodeData);
	}, [data]);

	const sum = useMemo(() => {
		return formatNumber(Math.abs(data.billSum || 0), '€');
	}, [data]);

	return (
		<Card className={'viewbill__paymentinformation'} title={t('payment-information.title')}>
			<Text>{data.accountIban ? t('payment-information.info') : t('payment-information.no-iban-info', {accountName: data.accountName || 'Organisaatio'})}</Text>

			{data.accountIban && (
				<div className={'viewbill__paymentinformation__content'}>
					<div className={'viewbill__paymentinformation__row'}>
						<Text strong>{t('payment-information.sum')}</Text>
						<Text>{sum}</Text>
					</div>
					<div className={'viewbill__paymentinformation__row'}>
						<Text strong>{t('payment-information.recipient')}</Text>
						<Text>{data.accountName}</Text>
					</div>
					<div className={'viewbill__paymentinformation__row'}>
						<Text strong>{t('payment-information.iban')}</Text>
						<Text>{formatIban(data.accountIban || '')}</Text>
					</div>
					<div className={'viewbill__paymentinformation__row'}>
						<Text strong>{t('payment-information.reference-number')}</Text>
						<Text>{data.billRefnum}</Text>
					</div>

					<div className={'viewbill__paymentinformation__row barcode'}>
						<Text strong>{t('payment-information.virtual-barcode')}</Text>
						<Text copyable={{tooltips: [t('payment-information.copy'), t('payment-information.copied')]}}>{barcode}</Text>
					</div>
				</div>
			)}
		</Card>
	);
};

export default PaymentInformation;