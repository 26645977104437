import {UseMutationResult, useMutation} from '@tanstack/react-query';

import Api from './api';
import {AxiosResponse} from 'axios';

export type RotateImageData = {
	id: string;
	direction: 'left' | 'right';
};

export type RotateImageResponse = {
	status: 'success' | 'error';
	url: string;
	id: string;
};

const postRotateImage = async ({id, direction}: RotateImageData) => {
	const data: AxiosResponse<RotateImageResponse> = await Api.post(`/files/rotate?key=${id}`, {data: {direction}});
	return data;
};

export const useRotateImage = (): UseMutationResult<AxiosResponse<RotateImageResponse>, unknown, RotateImageData, unknown> => {
	return useMutation({
		mutationFn: postRotateImage,
	});
};