import {v4 as uuid} from 'uuid';

import i18n from '../../../../../../i18n';
import {
	ItemType,
	Form
} from '../../../../types';
import {MileageTypeData, CategoryData} from '../../../../../../queries/useAccountInfo';
import {formatNumber} from '../../../../../../utils/formatNumber';

type Options = {
	mileageTypes: MileageTypeData[];
	categories: CategoryData;
};

export const generateForm = (id: string, type: ItemType, options: Options): Form | undefined => {
	if (type === 'invoice') {
		const categoryOptions = options.categories.accountExpenseCategories.map((val) => ({label: val, value: val, key: `${val}_${uuid()}`}));
		return {
			id,
			type,
			fields: [
				{
					id: 'description',
					required: true,
					type: 'textarea',
					placeholder: i18n.t('items.invoice.description'),
				},
				{
					id: 'category',
					required: true,
					type: 'select',
					label: i18n.t('items.invoice.category'),
					options: categoryOptions,
				},
				{
					id: 'sum',
					required: true,
					type: 'eur',
					label: i18n.t('items.invoice.sum'),
				},
				{
					id: 'files',
					required: true,
					type: 'file',
					label: i18n.t('items.invoice.attachment'),
				},
			]
		};
	}
	if (type === 'income') {
		const categoryOptions = options.categories.accountIncomeCategories.map((val) => ({label: val, value: val, key: `${val}_${uuid()}`}));
		return {
			id,
			type,
			fields: [
				{
					id: 'description',
					required: true,
					type: 'textarea',
					placeholder: i18n.t('items.income.description'),
				},
				{
					id: 'sum',
					required: true,
					type: 'eur',
					label: i18n.t('items.income.sum'),
				},
				{
					id: 'category',
					required: true,
					type: 'select',
					label: i18n.t('items.income.category'),
					options: categoryOptions,
				},
				{
					id: 'files',
					required: false,
					type: 'file',
					label: i18n.t('items.income.attachment'),
				},
			],
		};
	}
	if (type === 'mileage') {
		const mileageCategoryOptions = options.mileageTypes.map((obj) => ({
			label: `${obj.mileageType} (${formatNumber(obj.mileageAmount, '€/km')})`,
			value: obj.mileageId,
			key: `mileage_id_${obj.mileageId}`
		}));

		const categoryOptions = options.categories.accountExpenseCategories.map((val) => ({label: val, value: val, key: `${val}_${uuid()}`}));

		return {
			id,
			type,
			fields: [
				{
					id: 'description',
					required: true,
					type: 'textarea',
					placeholder: i18n.t('items.mileage.description'),
				},
				{
					id: 'category',
					required: true,
					type: 'select',
					label: i18n.t('items.mileage.category'),
					options: categoryOptions,
				},
				{
					id: 'mileageCategory',
					required: true,
					type: 'select',
					label: i18n.t('items.mileage.mileage-category'),
					options: mileageCategoryOptions,
				},
				{
					id: 'distance',
					required: true,
					type: 'distance',
					label: i18n.t('items.mileage.distance'),
				},
				{
					id: 'totalDistance',
					required: true,
					type: 'totalDistance',
					label: i18n.t('items.mileage.total'),
				},
			],
		};
	}
};