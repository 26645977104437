import {useQuery} from '@tanstack/react-query';

import Api from './api';
import {AxiosResponse, AxiosError} from 'axios';
import {getAccountSlug} from '../utils/getAccountSlug';

export type CategoryData = {
	accountExpenseCategories: string[];
	accountIncomeCategories: string[];
};

export type MileageTypeData = {
	accountId: number;
	mileageAmount: number;
	mileageId: number;
	mileageInUse: number;
	mileageType: string;
};

export type AccountInfoData = {
	accountName: string;
	accountHelpText: string;
	accountCollectIdentities: number;
	accountMileages: number;
	categories: CategoryData;
	mileageTypes: MileageTypeData[];
	passwordRequired: boolean;
	accountActive: number;
};

type AccountInfoResponse = {
	data: AccountInfoData;
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
	error: unknown;
}

const emptyData = {
	accountName: '',
	accountHelpText: '',
	accountCollectIdentities: 0,
	accountMileages: 0,
	categories: {
		accountExpenseCategories: [],
		accountIncomeCategories: [],
	},
	mileageTypes: [],
	passwordRequired: false,
	accountActive: 1,
};

const getAccountInfo = async () => {
	const slug = getAccountSlug();
	const {data}: AxiosResponse<AccountInfoData> = await Api.get(`/account-info/${slug}`);
	return data;
};

export const useAccountInfo = (): AccountInfoResponse => {
	const query = useQuery({
		queryKey: ['account-info'],
		queryFn: getAccountInfo,
		retry: (count, error: AxiosError) => {
			if (error.response?.status === 404) return false;
			return true;
		},
		refetchInterval: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});

	return {
		data: query.data || emptyData,
		isLoading: query.isLoading,
		isSuccess: query.isSuccess,
		isError: query.isError,
		error: query.error,
	};
};