import React, {useMemo} from 'react';
import {
	Button,
	Card,
	Form,
	Typography,
} from 'antd';
import {useTranslation, Trans} from 'react-i18next';

import {formatNumber} from '../../../../utils/formatNumber';

const {Paragraph} = Typography;

type Props = {
	totalSum: number;
	loading: boolean;
	edit: boolean;
};

const Summary = ({totalSum, loading, edit}: Props) => {
	const [form] = Form.useForm();
	const {t} = useTranslation();

	const formattedSum = useMemo(() => formatNumber(Math.abs(totalSum) || 0), [totalSum]);

	return (
		<Card title={t('summary.title')} className={'summary__card'}>
			<Form name={'submit-form'} form={form}>
				<Paragraph>
					<Trans values={{sum: formattedSum}} i18nKey={totalSum >= 0 ? 'summary.sum-to-be-paid' : 'summary.user-has-to-pay'} />
				</Paragraph>
				<Form.Item name={'submit'}>
					<Button
						type={'primary'}
						htmlType={'submit'}
						loading={loading}
					>
						{edit ? t('summary.update-invoice') : t('summary.send-invoice')}
					</Button>
				</Form.Item>
			</Form>
		</Card>
	);
};

export default Summary;