import React, {useState, useCallback, useMemo} from 'react';
import {Form as AntdForm} from 'antd';
import {FormFinishInfo, FormChangeInfo} from 'rc-field-form/lib/FormContext';
import {useTranslation} from 'react-i18next';
import {AxiosError, AxiosResponse} from 'axios';
import {useParams} from 'react-router-dom';

import Error from '../Error';
import TopBar from '../../components/TopBar';
import AccountNotActive from './components/AccountNotActive';
import PasswordModal from './components/PasswordModal';
import InfoSection from './components/InfoSection';
import ContactInformation from './components/ContactInformation';
import Items from './components/Items';
import Summary from './components/Summary';
import Success from '../../components/Success';
import {useWindowDimensions, windowBreakpoints} from '../../utils/screenSizes';
import {useTotalSum} from './hooks/useTotalSum';
import {saveUserInformationToCookies} from './utils/saveUserInformationToCookies';
import {ContactInformationType, FormData, ItemType} from './types';
import {useAccountInfo} from '../../queries/useAccountInfo';
import {useFormPost} from '../../queries/useFormPost';
import {useGetExistingBill} from '../../queries/useGetExistingBill';
import {useMessage} from '../../utils/useMessage';
import {BillResult} from '../../queries/types';

type Props = {
	edit?: boolean;
};

const Form = ({edit = false}: Props) => {
	const {t} = useTranslation();
	const {billId, editHash} = useParams();
	const {width: screenWidth} = useWindowDimensions();
	const message = useMessage();

	const [loading, setLoading] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(false);
	const [submittedForm, setSubmittedForm] = useState<BillResult>();
	const [formHasMileageItems, setFormHasMileageItems] = useState<boolean>(false);
	const [removedItems, setRemovedItems] = useState<string[]>([]);
	const [formPassword, setFormPassword] = useState<string>('');

	const {data: accountInfo, isLoading, isError: isErrorAccountInfo, error: errorAccountInfo} = useAccountInfo();
	const {mileageTypes, categories, accountCollectIdentities, accountMileages} = accountInfo;

	const {data: editBill, isError: isErrorEditBill, error: errorEditBill} = useGetExistingBill({billId: Number(billId), billEditHash: editHash});
	const {sum: totalSum, update, remove} = useTotalSum(editBill.expenses);

	const formPost = useFormPost(edit);

	const smallScreen: boolean = useMemo(() => {
		return screenWidth <= windowBreakpoints.small;
	}, [screenWidth]);

	const requirePassword = useMemo(() => {
		return !edit && accountInfo.passwordRequired;
	}, [edit, accountInfo]);

	const collectIdentities = useMemo(() => (
		accountCollectIdentities === 1 && formHasMileageItems
	), [accountCollectIdentities, formHasMileageItems]);

	// These two function below are used for tracking the total sum of the form
	// It uses some complicated logic in useTotalSum() hook :D
	// This should probably be reworked in the future
	const handleFormChange = useCallback((name: string, {changedFields, forms}: FormChangeInfo) => {
		if (name.includes('item_')) {
			if (changedFields.length > 0) {
				const field = changedFields[0].name.toString();
				if (field.includes('sum')) {
					const invoice = name.includes('invoice');
					const type = invoice ? 'invoice' : 'income';
					const {value} = changedFields[0];
					if (isNaN(value)) return update(name, type, 0);
					return update(name, type, Number(value));
				}
				// Different handling for mileages
				if (field.includes('mileageCategory') || field.includes('distance')) {
					const updatedForm = forms[name];
					const distance = updatedForm.getFieldValue('distance');
					const mileageCategory = updatedForm.getFieldValue('mileageCategory');
					const pricePerKm = mileageTypes.find((obj) => obj.mileageId === mileageCategory)?.mileageAmount || 0;
					if (isNaN(distance) || isNaN(pricePerKm)) return update(name, 'mileage', 0);
					return update(name, 'mileage', Number(distance) * Number(pricePerKm));
				}
			}
		}
	}, [update, mileageTypes]);

	const handleRemoveItem = useCallback((key: string) => {
		// Add invoice to removed items array if it is existing (doens't use uuid tha has - symbols)
		if (!key.includes('-')) {
			setRemovedItems([...removedItems, key]);
		}

		return remove(key);
	}, [remove, removedItems, setRemovedItems]);

	const handleFormSubmit = useCallback(async (name: string, {forms}: FormFinishInfo) => {
		if (name === 'submit-form') {
			const {'contact-information': contactInformationForm, ...otherFormsObject} = forms;

			const agreement = contactInformationForm.getFieldValue('agreement');

			if (!agreement) {
				return message.open({
					type: 'error',
					content: t('errors.accept-terms'),
					duration: 5,
				});
			}

			const otherFormKeys = Object.keys(otherFormsObject).filter((key) => key !== 'submit-form');

			const getType = (key: string): ItemType => {
				if (key.includes('invoice')) return 'invoice';
				if (key.includes('income')) return 'income';
				return 'mileage';
			};

			const otherForms = otherFormKeys.map((key) => ({id: key, type: getType(key), form: otherFormsObject[key]}));

			if (otherForms.length === 0) {
				return message.open({
					type: 'error',
					content: t('errors.add-at-least-one-item'),
					duration: 5,
				});
			}

			// Validate all fields
			try {
				await Promise.all([
					contactInformationForm.validateFields(),
					await Promise.all(
						otherForms.map(async (obj) => await obj.form.validateFields())
					),
				]);
			} catch (error) {
				console.error(error);
				return message.open({
					type: 'error',
					content: t('errors.form-validation-error'),
					duration: 5,
				});
			}

			const contactInformation: ContactInformationType = contactInformationForm.getFieldsValue(true);

			if (accountMileages === 0) {
				contactInformation.ssn = '';
			}

			const expenses = otherForms.map((obj) => ({id: obj.id, type: obj.type, ...obj.form.getFieldsValue(true)}));

			// Save information to cookies
			saveUserInformationToCookies(contactInformation);

			const data: FormData = {
				contactInformation,
				expenses,
				id: editBill.billId || 0,
				billEditHash: editHash || '',
				removedItems,
				formPassword,
			};

			setLoading(true);
			message.open({
				key: 'loading',
				type: 'loading',
				content: t('loading'),
			});

			formPost.mutate(data, {
				onSuccess: (result: AxiosResponse<BillResult>) => {
					if (result.status === 200 || result.status === 201) {
						setLoading(false);
						setSuccess(true);
						setSubmittedForm(result.data);
						message.open({
							key: 'loading',
							type: 'success',
							content: t(`success.${result.status === 200 ? 'updated' : 'created'}`),
							duration: 4,
						});
					}
				},
				onError: () => {
					message.open({
						key: 'loading',
						type: 'error',
						content: t('errors.something-went-wrong'),
						duration: 5,
					});
					setLoading(false);
				},
			});
		}
	}, [message, accountMileages, setLoading, formPost, t, removedItems, editBill, editHash, formPassword]);

	const isError = useMemo(() => {
		return isErrorAccountInfo || isErrorEditBill;
	}, [isErrorAccountInfo, isErrorEditBill]);

	if (isError) {
		if (isErrorAccountInfo && errorAccountInfo instanceof AxiosError) {
			const status = errorAccountInfo.response?.status;
			return <Error error404={status === 404} />;
		}
		if (isErrorEditBill && errorEditBill instanceof AxiosError) {
			message.open({
				key: 'invalid-edit-url',
				type: 'error',
				content: t('errors.edit-link-invalid'),
				duration: 5,
			});
			const status = errorEditBill.response?.status;
			return <Error error404={status === 404} />;
		}
		return <Error />;
	}

	return (
		<div className={`form ${isLoading ? 'hidden' : 'visible'}`}>
			<TopBar title={accountInfo.accountName || 'Kululaskut.fi'} smallScreen={smallScreen} />

			{!accountInfo.accountActive && <AccountNotActive />}
			{accountInfo.accountActive && <PasswordModal passwordRequired={requirePassword} setFormPassword={setFormPassword} />}

			{success ? (
				<Success data={submittedForm} showCreateNewButton={true} />
			) : (
				<div className={'form__content'}>
					<InfoSection
						edit={edit}
						billRefnum={editBill.billRefnum}
						description={edit ? editBill.billEditComments : accountInfo.accountHelpText}
						smallScreen={smallScreen}
					/>

					<AntdForm.Provider
						onFormChange={handleFormChange}
						onFormFinish={handleFormSubmit}
					>
						<ContactInformation collectIdentities={collectIdentities} editBill={edit ? editBill : null} />
						<Items
							handleRemoveItem={handleRemoveItem}
							mileageTypes={mileageTypes}
							categories={categories}
							setFormHasMileageItems={setFormHasMileageItems}
							editBill={edit ? editBill : null}
						/>
						<Summary totalSum={totalSum} loading={loading} edit={edit} />
					</AntdForm.Provider>
				</div>
			)}

		</div>
	);
};

export default Form;