import React, {useCallback} from 'react';
import {
	InputNumber,
	Form,
} from 'antd';
import {NamePath} from 'antd/es/form/interface';

type EurProps = {
	id: string;
	required: boolean;
	label?: string;
};

const Eur = ({id, required, label = ''}: EurProps) => {
	// Validate number input
	const validate = useCallback((value: string, setFieldValue: (name: NamePath, value: string) => void) => {
		if (value === undefined) return Promise.resolve();
		if (!isNaN(Number(value)) && Number(value) === 0) return Promise.reject();
		setFieldValue(id, value);
		return Promise.resolve();
	}, [id]);

	return (
		<Form.Item
			name={id}
			label={label}
			colon={false}
			className={'form__item'}
			rules={[
				{required},
				({setFieldValue}) => ({
					validator: (_, value) => validate(value, setFieldValue),
				}),
			]}
			required={false}
		>
			<InputNumber
				addonAfter={'€'}
				placeholder={'0,00'}
				decimalSeparator={','}
				min={0}
				step={0.01}
				precision={2}
				className={'form__item__eur'}
			/>
		</Form.Item>
	);
};

export default Eur;