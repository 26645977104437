import React from 'react';
import {
	Select as AntdSelect,
	Form,
} from 'antd';

import {Option} from '../../../../../types';

type SelectProps = {
	id: string;
	required: boolean;
	label?: string;
	options?: Option[];
};

const Select = ({id, required, label = '', options = []}: SelectProps) => {
	if (options.length === 0) return null;

	return (
		<Form.Item
			name={id}
			label={label}
			colon={false}
			className={'form__item'}
			rules={[{required}]}
			required={false}
		>
			<AntdSelect
				popupMatchSelectWidth={false}
				className={'form__item__select'}
				options={options}
			/>
		</Form.Item>
	);
};

export default Select;