import React from 'react';
import {FormInstance} from 'antd';

import {Field} from '../../../../types';
import {
	TextArea,
	Select,
	Eur,
	File,
	Distance,
	TotalDistance,
} from './components';
import {ExistingExpense} from '../../../../../../queries/useGetExistingBill';

type InputsProps = Field & {
	existingData?: ExistingExpense,
	formInstance: FormInstance;
};

const Inputs = ({
	id,
	required,
	type,
	label,
	placeholder,
	options,
	existingData,
	formInstance,
}: InputsProps) => {
	if (type === 'textarea') {
		return <TextArea id={id} placeholder={placeholder} required={required} />;
	}
	if (type === 'select') {
		return <Select id={id} label={label} options={options} required={required} />;
	}
	if (type === 'eur') {
		return <Eur id={id} label={label} required={required} />;
	}
	if (type === 'file') {
		return <File id={id} label={label} required={required} formInstance={formInstance} existingFiles={existingData ? existingData.files : []} />;
	}
	if (type === 'distance') {
		return <Distance id={id} label={label} required={required} />;
	}
	if (type === 'totalDistance') {
		return <TotalDistance id={id} label={label} formInstance={formInstance} required={required} />;
	}
	return null;
};

export default Inputs;