import React, {useMemo} from 'react';
import {Typography, Alert} from 'antd';
import {useTranslation} from 'react-i18next';

const {Title, Text} = Typography;

type Props = {
	edit: boolean;
	billRefnum?: number;
	description?: string;
	smallScreen: boolean;
};

const InfoSection = ({edit, billRefnum, description, smallScreen}: Props) => {
	const {t} = useTranslation();

	const titleLevel: 1 | 3 = useMemo(() => {
		if (smallScreen) return 3;
		return 1;
	}, [smallScreen]);

	const title = useMemo(() => {
		if (edit && billRefnum) {
			return t('edit.form-title', {num: billRefnum});
		}
		return t('form-title');
	}, [edit, billRefnum, t]);

	const alertDescription = useMemo(() => {
		if (edit) {
			return (
				<>
					<Text strong>{t('edit.accountant-comments')}</Text>
					<br/>
					<Text className={'infosection__text'}>{description}</Text>
				</>
			);
		}
		return <Text className={'infosection__text'}>{description}</Text>;
	}, [edit, description, t]);

	return (
		<>
			<Title level={titleLevel} className={'infosection__title'}>{title}</Title>

			{description && <Alert message={alertDescription} type={'info'} />}
		</>
	);
};

export default InfoSection;