const toNumber = (val: number | string): number | null => {
	const num = Number(val);
	if (isNaN(num)) return null;
	return num;
};

export const formatNumber = (sum?: number | null | string, symbol?: string): string => {
	if (sum === null || sum === undefined) return '';
	const num = toNumber(sum);
	if (num === null) return '';
	const rounded = num.toFixed(2);
	const parts = rounded.split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	if (symbol) {
		return `${parts.join(',')} ${symbol}`;
	}
	return parts.join(',');
};