import React, {useCallback, useState, useMemo, useEffect} from 'react';
import {v4 as uuidv4} from 'uuid';

import Buttons from './components/Buttons';
import Description from '../Description';
import Form from './components/Form';
import {ItemType, Item} from '../../types';
import {MileageTypeData, CategoryData} from '../../../../queries/useAccountInfo';
import {useAccountInfo} from '../../../../queries/useAccountInfo';
import {ExistingBill, ExistingExpense} from '../../../../queries/useGetExistingBill';

type Props = {
	handleRemoveItem: (key: string) => void;
	mileageTypes: MileageTypeData[];
	categories: CategoryData;
	setFormHasMileageItems: (value: boolean) => void;
	editBill: ExistingBill | null;
};

const Items = ({handleRemoveItem, mileageTypes, categories, setFormHasMileageItems, editBill}: Props) => {
	const [items, setItems] = useState<Item[]>([]);
	const {data} = useAccountInfo();
	const {accountMileages} = data;

	const addItem = useCallback((type: ItemType) => {
		const id = `item_${type}_${uuidv4()}`;
		const newItems = [...items, {id, type}];
		setItems(newItems);
	}, [items, setItems]);

	const deleteItem = useCallback((id: string) => {
		handleRemoveItem(id);
		const newItems = [...items.filter((item) => item.id !== id)];
		setItems(newItems);
	}, [items, setItems, handleRemoveItem]);

	const formHasMileageItems = useMemo(() => {
		const hasMileages = items.find((item) => item.type === 'mileage');
		return !!hasMileages;
	}, [items]);

	useEffect(() => {
		setFormHasMileageItems(formHasMileageItems);
	}, [formHasMileageItems, setFormHasMileageItems]);

	const getExpenseType = useCallback((expense: ExistingExpense): ItemType => {
		if (expense.expenseMileage) return 'mileage';
		if (expense.expenseAmount > 0) return 'invoice';
		return 'income';
	}, []);

	// Add items automatically if we are editing existing bill
	useEffect(() => {
		if (editBill !== null) {
			const newItems: Item[] = editBill.expenses.map((expense) => {
				const type = getExpenseType(expense);
				return {
					id: `item_${type}_${expense.expenseId}`,
					type,
					existingData: expense,
				};
			});
			setItems(newItems);
		}
	}, [editBill, getExpenseType]);

	return (
		<div className={'items'}>
			<div className={'items__content'}>
				{items.map((item) => (
					<Form
						key={item.id}
						deleteItem={deleteItem}
						mileageTypes={mileageTypes}
						categories={categories}
						{...item}
					/>
				))}

				<Buttons addItem={addItem} mileagesEnabled={accountMileages === 1} />
			</div>

			<div className={'items__description'}>
				<Description value={'items.description'} />
			</div>
		</div>
	);
};

export default Items;