import React, {useCallback, useEffect, useState} from 'react';
import {Modal, Input, Button, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import {AxiosError} from 'axios';

import {useAuthorizePassword} from '../../../../queries/useAuthorizePassword';
import {useMessage} from '../../../../utils/useMessage';

const {Text, Title} = Typography;

type Props = {
	passwordRequired: boolean;
	setFormPassword: (value: string) => void;
};

const PasswordModal = ({passwordRequired, setFormPassword}: Props) => {
	const {t} = useTranslation();
	const {isLoading, ...authorizePassword} = useAuthorizePassword();
	const message = useMessage();

	const [password, setPassword] = useState<string>('');
	const [modalVisible, setModalVisible] = useState<boolean>(false);

	useEffect(() => {
		if (passwordRequired) {
			setModalVisible(true);
		}
	}, [passwordRequired]);

	const onChangePassword = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);
	}, []);

	const onSubmit = useCallback(() => {
		authorizePassword.mutate({password}, {
			onSuccess: () => {
				setModalVisible(false);
				setFormPassword(password);
			},
			onError: (error) => {
				if (error instanceof AxiosError) {
					if (error.response?.status === 401) {
						return message.open({
							type: 'error',
							content: t('password.invalid-password'),
							duration: 5,
						});
					}
				}
				return message.open({
					type: 'error',
					content: t('errors.something-went-wrong'),
					duration: 5,
				});
			},
		});
	}, [password, authorizePassword, message, t, setFormPassword]);

	return (
		<Modal
			open={modalVisible}
			footer={null}
			closeIcon={null}
		>
			<div className={'passwordmodal'}>
				<Title level={3}>{t('password.label')}</Title>
				<Input
					placeholder={t('password.input-placeholder')}
					value={password}
					onChange={onChangePassword}
					type={'password'}
				/>
				<Text italic>{t('password.info')}</Text>
				<Button type={'primary'} onClick={onSubmit} loading={isLoading} disabled={password === ''}>
					{t('password.login')}
				</Button>
			</div>
		</Modal>
	);
};

export default PasswordModal;