import React, {useMemo} from 'react';
import {Typography} from 'antd';
import LanguageSelect from '../LanguageSelect';

const {Title} = Typography;

type Props = {
	title: string;
	smallScreen: boolean;
};

const TopBar = ({title, smallScreen}: Props) => {
	const titleLevel: 2 | 4 = useMemo(() => {
		if (smallScreen) return 4;
		return 2;
	}, [smallScreen]);

	return (
		<div className={'topbar'}>
			<div className={'topbar__content'}>
				<Title level={titleLevel} className={'topbar__title'}>{title}</Title>

				<LanguageSelect />
			</div>
		</div>
	);
};

export default TopBar;